<template>

  <div>
    <div v-for="(item, index) in value" :key="index" v-if="CloseAll.indexOf(item.tid) < 0">


      <div class="flex-right site_all" :class="`site_${item.tid}`" >
        <div class="site_box"  v-for="(obj, key) in item.data" :key="key" v-if="CloseAll.indexOf(`${item.tid}_${key}`) < 0">

          <div class="btn-template">

            <a v-if="obj.type == 'a'" :href="WebSetting.LINELINK" :class="obj.btnStyle" target="_blank" :data-translate="$t('store.customer.online_howru') + WebSetting.LINEID">
              <i :class="obj.iconStyle"></i>
              <span>{{ $t(obj.name1) }}</span>
              <span class="lind_id" v-if="obj.show">{{ WebSetting.LINEID }}</span>
            </a>

            <b-button v-if="obj.type == 'b'" v-show="obj.show" :to="obj.to" @click="btnFlextoggleModal( obj.needLogin, obj.click[0], obj.click[1])" :class="obj.btnStyle">
              <i :class="obj.iconStyle"></i>
              <span>{{ $t(obj.name1) }}</span>
              <span>{{ $t(obj.name2) }}</span>
            </b-button>

            <template v-if="obj.type == 'c'">
              <b-button :class="obj.btnStyle">
                <i :class="obj.iconStyle"></i>
                <span>{{ $t("store.customer.service") }}</span>
                <div class="lkservice_box">
                  <div class="lkservice_box-s1">
                    <b-button v-if="HasLivechat" :href="WebSetting.LIVECHAT" class="btn-service" target="_blank"></b-button>

                    <b-button v-if="HasLineLink" :href="WebSetting.LINELINK" target="_blank" class="btn-line" :data-text='$t("store.customer.service_line")'></b-button>

                    <b-button v-if="HasTelegram" :href="WebSetting.TELRGRAM" target="_blank" class="btn-telegram" :data-text='$t("store.customer.telegram")'></b-button>

                    <b-button v-if="HasFacebook" :href="WebSetting.FACEBOOK" target="_blank" class="btn-facebook" :data-text='$t("store.customer.facebook")'></b-button>

                    <b-button v-if="HasTwitter" :href="WebSetting.TWITTER"  target="_blank" class="btn-twitter" :data-text='$t("store.customer.twitter")'></b-button>

                    <b-button v-if="HasInstagram" :href="WebSetting.INSTAGRAM"  target="_blank" class="btn-instagram" :data-text='$t("store.customer.instagram")'></b-button>

                    <b-button v-if="HasWhatsApp" :href="WebSetting.WHATSAPP"  target="_blank" class="btn-whatsapp" :data-text='$t("store.customer.whatsapp")'></b-button>

                    <b-button v-if="HasNewIn" :href="WebSetting.NEWIN" target="_blank" class="btn-newin" :data-text='$t("store.customer.service_tw")'></b-button>
                  </div>
                </div>
              </b-button>
            </template>

            <a v-if="obj.type == 'd'" :href="obj.url" :class="obj.btnStyle" target="_blank">
              <i :class="obj.iconStyle"></i>
              <span>{{ $t(obj.name1) }}</span>
            </a>

            <a v-if="obj.type == 'e'" :href="WebSetting.LIVECHAT" :class="obj.btnStyle" target="_blank">
              <i :class="obj.iconStyle"></i>
              <span>{{ $t(obj.name1) }}</span>
              <!-- <span class="lind_id" v-if="obj.show">{{ $t("store.customer.online_hi") }}</span> -->
            </a>

          </div>

          <div class="close_selfbox-s" @click="closefloatBtn(`${item.tid}_${key}`)">X</div>
        </div>
        <div class="close_selfbox" @click="closefloatBtn(item.tid)">{{ $t("store.btn.close_all") }}</div>
      </div>


    </div>
  </div>

</template>
<script>
let floatBtn = [];
try { floatBtn = require("$json/floatBtn.json"); } catch (e) {}

import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      floatBtn,
    };
  },
  created() {
    if (this.floatBtn == null  || this.floatBtn.length == 0)
    this.floatBtn = getJsonFile(process.env.DEF_webStyle, 'floatBtn');
  },
  name: "userModBtn2",
  props: [ "value" ],
  methods: {
    closefloatBtn(key) {
      this._setCloseAll(key);
    },
    btnFlextoggleModal(status, modal, btnTag) {
      if (modal == "off")
        return ;
      if (status && this.$auth.$state.loggedIn === false)
        this.$router.push({ name: 'account-login', params: null, query: null });
      else
        this.$root.$emit("bv::toggle::modal", modal, btnTag);
    },
    toggleModal() {
      document.getElementById("livechat").classList.toggle("show");
    },
    ...mapMutations(["_setCloseAll"])
  },
  computed: {
    HasLivechat(){
      return Object.keys(this.WebSetting).indexOf('LIVECHAT') && this.WebSetting.LIVECHAT;
    },
    HasLineLink(){
      return Object.keys(this.WebSetting).indexOf('LINELINK') && this.WebSetting.LINELINK;
    },
    HasTelegram(){
      return Object.keys(this.WebSetting).indexOf('TELRGRAM') && this.WebSetting.TELRGRAM;
    },
    HasInstagram(){
      return Object.keys(this.WebSetting).indexOf('INSTAGRAM') && this.WebSetting.INSTAGRAM;
    },
    HasTwitter(){
      return Object.keys(this.WebSetting).indexOf('TWITTER') && this.WebSetting.TWITTER;
    },
    HasFacebook(){
      return Object.keys(this.WebSetting).indexOf('FACEBOOK') && this.WebSetting.FACEBOOK;
    },
    HasWhatsApp(){
      return Object.keys(this.WebSetting).indexOf('WHATSAPP') && this.WebSetting.WHATSAPP;
    },
    HasNewIn(){
      return Object.keys(this.WebSetting).indexOf('NEWIN') && this.WebSetting.NEWIN;
    },
    ...mapState(["WebSetting", 'CloseAll']),
  }
};
</script>
