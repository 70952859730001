<template>
  <header>
    <div class="nav-header" :class="$auth.$state.loggedIn? 'after':'before'"><!-- 登入前:before,  登入後:after-->
      <div class="header-wrap">
        <nuxt-link to="/" class="logo"></nuxt-link>
        <ul class="navbar">
          <modNavLink v-for="(item, index) in qy8" v-model="qy8[index]" :key="index" v-if="item.type" />
        </ul>
        <modNavRtB v-if="$auth.$state.loggedIn !== true" /><!-- 登入前 -->
        <allBackWalletBtn v-if="$auth.$state.loggedIn === true" /><!-- 登入後 -->
        <modNavRtA02 v-if="$auth.$state.loggedIn === true" /><!-- 登入後 -->
        <dayNight/>
        <vuei18n />
      </div>
    </div>
  </header>
</template>
<script>
const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
const dayNight = require(`~/components/${process.env.DEF_daynight}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import allBackWalletBtn from "~/components/desktop/allBackWalletBtn.vue";
  import modNavRtA02 from "~/components/desktop/header/modNavRtA02.vue";
  import modNavCtrB from "~/components/desktop/header/modNavCtrB.vue";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  import modNavLink from "~/components/desktop/header/modNavLink.vue";
  export default {
    components:{
      allBackWalletBtn,
      modNavRtA02,
      modNavCtrB,
      modNavRtB,
      modNavLink,
      dayNight,
      vuei18n
    },
    data(){
      return{
        qy8:[],
        base: [],
        blogqy8: [
          {
            type:'b',
            url:'https://blog.qywin88.com/',
            blank: true
          }
        ],
        blogstar: [
          {
            type:'b',
            url:'https://7s588.com/',
            blank: true
          }
        ],
        blogtyc: [
          {
            type:'b',
            url:'https://www.scb588.com/',
            blank: true
          }
        ],
        // just:[
        //   {
        //     type:'c',
        //     show: true
        //   }
        // ]
      }
    },
    mounted() {
      this.init();
      this.changeLang();
    },
    methods: {
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      qy8Data(){
        let data = this.baseData();
        if (this.DEF_webStyle == 'qy8')
          data.push(...this.blogqy8);
        else if (this.DEF_webStyle == 'star')
          data.push(...this.blogstar);
        else if (this.DEF_webStyle == 'tyc' || this.DEF_webStyle == 'tycn')
          data.push(...this.blogtyc);
        return data;
      },
      changeLang(){
        this.base = this.baseData();
        this.qy8  = this.qy8Data();
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'ErrorMsg']),
      ...mapGetters(["Meun"]),
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
        },
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      }
    }
  }
</script>
