<template>
    <b-dropdown id="dropdown-left" variant="link" class="nav-right">
      <template v-slot:button-content>
        <ul>
          <li v-if="ShowLevel && GroupName">
            <i class="icon icon-rank"></i>
            <q>{{ $auth.$state.user.group_name }}</q>
          </li>
          <li>
            <i class="icon icon-user"></i>
            <q>{{ $t("store.user.account") }}: <span>{{ UserName }}</span></q>
          </li>
          <li>
            <i class="icon icon-coin"></i>
            <q>{{ $t("store.user.coin") }}:
              <b-spinner variant="variant" type="grow" small v-if="AccountWalletMainLoading" label="Spinning"></b-spinner>
              <span v-else>{{ AccountWalletMain | currencyInt }}</span>
              <i class="icon icon-refresh" @click="RefreshAccountWallet"></i>
            </q>
          </li>
        </ul>
      </template>

      <b-dropdown-item v-if="!SeamlessWallet" variant="popup" class="nav-afterbox wallet-turning" @click="toggleModal" ref="btnToggle" v-b-modal.modal-tall>
        <i class="icon icon-turning"></i><span>{{ $t("store.transfer.points_records") }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="ShowConversion" variant="popup" class="nav-afterbox wallet-gift" @click="ModalTransfer" ref="btnToggle" v-b-modal.modal-tall>
        <i class="icon icon-star"></i><span>{{ $t("store.transfer.turning_coin") }}</span>
      </b-dropdown-item>
      <template v-for="(box, index) in after">
        <b-dropdown-item v-show="box.show" :to="box.url" :class="`nav-afterbox ${ box.class}`">
          <i :class="box.icon"></i>
          <span>{{ box.title }}</span>
        </b-dropdown-item>
      </template>
      <b-dropdown-item class="nav-afterbox qyprofile" to="/user/profile">{{ $t("store.user.profile") }}</b-dropdown-item>
      <b-dropdown-item class="qyout btn btn-log" href="#" @click="OnLogout">{{ $t("store.btn.logout") }}</b-dropdown-item>
    </b-dropdown>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "modNavRtA02",
  data(){
    return {
        AccountWalletMain: 0,
        AccountWalletMainLoading: false,
        show: true,
        after: this.handleAfter(),
    }
  },
  created() {
    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  mounted(){
    this.after = this.handleAfter();
  },
  methods: {
    ModalTransfer() {
      this.$root.$emit("bv::toggle::modal", "modalTransferPoint", "#btnToggle");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle");
    },
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    handleAfter() {
      return [
        {
          url: "/wallet/bank",
          title: this.$t("store.wallet.digital_wallet"),
          icon: "icon icon-wallet",
          show: true,
          class: "wallet-bank"
        },
        {
          url: "/wallet/treasure",
          title: this.$t("store.withdrawal.wallet_treasure"),
          icon: "icon icon-diamond",
          show: true,
          class: "wallet-treasure"
        },
        {
          url: "/wallet/deposit",
          title: this.$t("store.desopit.desopit_now"),
          icon: "icon icon-deposit",
          show: true,
          class: "wallet-deposit"
        },
        {
          url: "/share/share",
          title: this.$t("store.share"),
          icon: "icon icon-moneybag",
          show: this.ShowShare,
          class: "wallet-share"
        },
        {
          url: "/system/mail",
          title: this.$t("store.web_mail"),
          icon: "icon icon-mail",
          show: true,
          class: "wallet-mail"
        },
      ];
    },
      ...mapActions("account", ["GetAccountWallet"]),
  },
  watch: {
    "$auth.$state.loggedIn"(loggedIn) {
    },
    "$auth.$state.user"(user) {
    },
  },
  computed:{
    GroupName() {
      if (this.$auth.$state.user.group_name && this.$auth.$state.user.group_name != "")
        return this.$auth.$state.user.group_name;
      else
        return false;
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    ShowShare() {
      return this.WebSetting.SHOW_SHARE === "1";
    },
    ShowConversion() {
      return this.WebSetting.SHOW_CONVERSION === "1";
    },
    ...mapState(['WebSetting']),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
    ...mapGetters('webSetting', ['SeamlessWallet'])
  }
};
</script>