<template>
  <div class="idxInfo7">
    <div class="img"></div>
    <div class="title"></div>
    <div class="content">
      <p v-for="(item, index) in idxApp" :key="index">
        {{  item.content }}
      </p>
    </div>
    
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        idxApp: null,
      };
    },
    created: function () {
      if (this.idxApp == null)
        this.idxApp = getJsonFile(process.env.DEF_webStyle, 'idxApp');
    },
    mounted: function () {
    },
    computed: {
      ...mapState(["WebSetting"]),
    },
  };
</script>
